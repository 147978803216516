<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateBattleRoundMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateBattleRoundMutation = gql`
    mutation($id: ID! $creatorProgress: JSON, $opponentProgress: JSON  ) {
      updatePublicBattle(id: $id, data: {
        creatorProgress: $creatorProgress
        opponentProgress: $opponentProgress
      }) {
        data {
          attributes {
            creatorProgress
            opponentProgress
            battleRound {
              round
            }
          }
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateBattleRound } = useMutation(updateBattleRoundMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateBattleRound', result: updateBattleRound });
  },
};
</script>
