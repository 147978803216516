<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCurrentBattle',
  watch: {
    currentBattle() {
      this.$store.commit('setStrapiResult', { vuexModule: 'training', state: 'currentBattle', data: this.currentBattle?.publicBattle?.data?.attributes });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const queryCurrentBattle = gql`
      query($battleId: ID!) {
        publicBattle(id: $battleId) {
          data {
            id
            attributes {
              creatorFinished
              creatorResultsSeen
              opponentFinished
              opponentResultsSeen
              creatorProgress
              opponentProgress
              battleRound { round }
              opponent {
                data {
                  id
                  attributes {
                    nickname
                    avatar {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
              creator {
                data {
                  id
                  attributes {
                    nickname
                    avatar {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      battleId: computed(() => store.state.training.currentTraining.battleId),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: currentBattle, execute: queryCurrentBattleEx } = useQuery({
      query: queryCurrentBattle,
      variables,
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'queryCurrentBattleEx', result: queryCurrentBattleEx });

    return { currentBattle };
  },
};
</script>
