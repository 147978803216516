<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'finishBattleMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const finishBattleMutation = gql`
    mutation($id: ID! $creatorFinished: Boolean, $opponentFinished: Boolean, $creatorResultsSeen: Boolean, $opponentResultsSeen: Boolean  ) {
      updatePublicBattle(id: $id, data: {
        creatorFinished: $creatorFinished
        creatorResultsSeen: $creatorResultsSeen
        opponentFinished: $opponentFinished
        opponentResultsSeen: $opponentResultsSeen
      }) {
        data {
          id
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: finishBattle } = useMutation(finishBattleMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'finishBattle', result: finishBattle });
  },
};
</script>
