<script>
export default {
  name: 'points',

  computed: {
    updatePoints() {
      return this.$store.state.updatePoints;
    },
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
  },

  methods: {
    savePoints({ pointCategory }) {
      console.log('trying to save some points..');
      console.log(pointCategory);
      const points = this.me?.userData?.data?.attributes?.points;
      const currentPointIndex = points[pointCategory].findIndex((course) => course.courseId === this.currentCourseId);
      console.log('current point index');
      console.log(currentPointIndex);
      if (currentPointIndex !== -1) {
        points[pointCategory][currentPointIndex].points += 1;
      } else {
        points[pointCategory].push({
          courseId: this.currentCourseId,
          points: 1,
        });
      }
      this.updatePoints({ id: this.userDataId, points });
    },
  },

};
</script>
