<template>
  <div class="w-full p-3 flex flex-row justify-between items-center font-visby cursor-pointer"
    :class="{'border-b-2 border-new-yellow' : border,
    'bg-new-black' : currentProgress?.selectedAnswersIds?.includes(answerID) && !showAnswers,
    'bg-red-600' : currentProgress?.selectedAnswersIds?.includes(answerID) && showAnswers && !correct,
    'bg-green-500' : currentProgress?.selectedAnswersIds?.includes(answerID) && showAnswers  && correct,
    'bg-green-200' : !currentProgress?.selectedAnswersIds?.includes(answerID) && showAnswers && correct,}"
    @click="click">
    <div class="flex-grow pr-2 flex flex-row justify-between items-center">
      <tipTap :content="label" displayType="clean" :color="currentProgress?.selectedAnswersIds?.includes(answerID) ? 'white' : 'black'" />
      <div v-if="currentProgress?.selectedAnswersIds?.includes(answerID) && showAnswers && correct"
        class="ml-2 w-8 h-8 flex-shrink-0 flex flex-row justify-center items-center rounded-full bg-white">
        <checkSVG color="green" />
      </div>
      <div v-if="currentProgress?.selectedAnswersIds?.includes(answerID) && showAnswers && !correct"
        class="ml-2  w-8 h-8 flex-shrink-0 flex flex-row justify-center items-center rounded-full bg-white">
        <xSVG />
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import checkSVG from '../../../assets/svg/check.vue';
import xSVG from '../../../assets/svg/x.vue';

// Components
import tipTap from '../../../symbols/tipTap.vue';

export default {
  name: 'questionSelector',
  props: ['label', 'border', 'questionID', 'answerID', 'maxAnswers', 'correctAnswersIds'],
  mixins: [iosVibrations],
  components: { checkSVG, xSVG, tipTap },

  computed: {
    currentProgress() {
      return this.$store.getters.currentProgress(this.questionID);
    },
    currentTraining() {
      return this.$store.state.training.currentTraining;
    },
    reviewMode() {
      return this.$store.state.training?.settings?.review;
    },
    showAnswers() {
      if (this.currentProgress.result !== null || this.currentTraining.finished || (this.reviewMode && this.currentTraining?.type === 'battle')) {
        return true;
      }
      return false;
    },
    currentlySelectedAnswers() {
      return this.$store.getters.currentlySelectedAnswers(this.questionID);
    },
    correct() {
      return this.correctAnswersIds.includes(Number(this.answerID));
    },
  },

  methods: {
    click() {
      if (!this.showAnswers) {
        if (this.currentlySelectedAnswers < this.maxAnswers || this.currentProgress?.selectedAnswersIds?.includes(this.answerID)) {
          this.setAnswer();
        } else {
          this.iosVibrate(4); // warning
        }
      }
    },

    setAnswer() {
      this.iosVibrate(1);
      this.$store.commit('addAnswer', { answerID: this.answerID, questionID: this.questionID });
    },
  },
};
</script>
